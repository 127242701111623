import React, { useEffect, useState } from 'react';
import { IonContent, IonHeader, IonButton, IonPage, IonTitle, IonToolbar, IonImg, IonAlert } from '@ionic/react';
import { useHistory, useLocation } from "react-router-dom";
import './Splash.scss';
import { baseUrl } from '../helpers/configHelper';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import * as headerFooterStore from '../store/HeaderFooterStore';
import { continueFromInitialPage, getConfig } from '../helpers/configHelper';
import { Base64 } from 'js-base64';
import * as ColleagueStore from '../store/ColleagueStore';

const SplashBack = styled.div`
    background-position: center top;
    background-size:cover;
    background-repeat: no-repeat;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    height: 100%;
    transform: translateY(-50%);
    color: #FFF;
    background-image: url(${baseUrl()}/images/assets/SplashMain_${getConfig().imageThemeID}.jpg);
`;

const SplashBanner = styled.img`
    position: absolute;
    width: 100%;
    object-fit: scale-down;
    bottom: 0;
    left: 0;
`;

const grantCookiePermissions = () => {
    (window as any).gtag('consent', 'update', {
        'ad_storage': 'granted',
        'analytics_storage': 'granted'
    });
}

const SplashPage: React.FC = () => {
    let history = useHistory();

    const dispatch = useDispatch();
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const [showConsentAlert, setShowConsentAlert] = useState(false);
    const [shouldShowCookieConsent, setShouldShowCookieConsent] = useState(false);
    const boundSetShowModal = bindActionCreators(headerFooterStore.actionCreators.setShowModal, useDispatch());
    const boundSetHasStartedApp = bindActionCreators(headerFooterStore.actionCreators.setHasStartedApp, useDispatch());
    const [message, setMessage] = useState("");
    const search = useLocation().search;
    const pd = new URLSearchParams(search).get('pd');

    useEffect(() => {
        let cookieConsent = localStorage.getItem('cookieConsent');
        let newOrder = new URLSearchParams(search).get('newOrder');

        if(cookieConsent == null){
            setShouldShowCookieConsent(true);
        }
        else if(cookieConsent === "granted"){
            grantCookiePermissions();
        }

        if (pd) {
            let decodeEmailAndMoreCard = Base64.decode(JSON.stringify(pd));
            let splitDecodeByComma = decodeEmailAndMoreCard.split(',');

            localStorage.setItem('email', splitDecodeByComma[0]);
            localStorage.setItem('staffCardNumber', splitDecodeByComma[1]);
        }

        if (newOrder && localStorage.getItem('isLoggedIn')) {
            localStorage.removeItem('isLoggedIn');
            localStorage.removeItem('pfIdentifier');
            dispatch(ColleagueStore.loggedIn(false));
        }

        //fetch(baseUrl() + '/stores/GetSplashScreenMessage').then((res) => res.text()).then((data) => {
        //    setMessage(data);
        //})        
    }, [])

    return (
        <IonPage>
            {/* 
            <IonAlert
                isOpen={showPrivacyPolicy}
                onDidDismiss={() => { setShowPrivacyPolicy(false) }}
                header={"Terms and Conditions Policy"}
                message={"By clicking continue, you agree to our Terms and Conditions Policy."}
                buttons={[
                    {
                        text: "Terms & Conditions",
                        handler: () => {
                            boundSetShowModal("TC", true, true);
                        }
                    },
                    {
                        text: "Continue",
                        handler: () => {
                            localStorage.setItem("policyAccepted", "true");
                            continueFromInitialPage(history, boundSetHasStartedApp, "/storesearch");
                        }
                    }
                ]}
            />*/}

            {/* 
            <IonAlert
                isOpen={showConsentAlert}
                onDidDismiss={() => { setShowConsentAlert(false); setShowPrivacyPolicy(true); setShouldShowCookieConsent(false); }}
                message={"To help us improve this service, we use analytics cookies. These cookies won't identify or track you outside this web app."}
                buttons={[
                    {
                        text: "Decline",
                        handler: () => {
                            localStorage.setItem('cookieConsent', 'denied');
                        }
                    },
                    {
                        text: "Accept",
                        handler: () => {
                            localStorage.setItem('cookieConsent', 'granted');
                            grantCookiePermissions();
                        }
                    }
                ]}/>*/ }
            <IonContent fullscreen>
                <div className="splash">
                    <div className="splash-row">
                        <img alt="Morrisions MK" className="logo-background" src={`${baseUrl()}/images/assets/logo-background.png?v=2`}></img>
                    </div>
                    <div className="splash-row splash-container">
                        <div>
                            <IonButton className="button" color="light" disabled={shouldShowCookieConsent}
                                onClick={() => {  continueFromInitialPage(history, boundSetHasStartedApp, "/storesearch") }}>Start Your Order</IonButton>
                            <div className="splashVersion">b1922</div>                    
                        </div>
                    </div>
                    {shouldShowCookieConsent && <div className="consent-chaffinch">
                        <div className="content-wrapper">
                            <div className="content">
                                <div className="title">Giving you the best ordering experience</div>
                                <div className="information">We’d like your consent to let us use cookies to gather and analyse information about how you use this app to help us improve our service. These cookies won’t identify or track you outside of this app.</div>
                                <div className="terms-and-conditions">By continuing, you agree to our <span className="link" onClick={() => { boundSetShowModal("TC", true, true); }}>Terms &amp; Conditions</span> Policy</div>
                                <div className="decline"><span className="link" onClick={() => {
                                        setShowConsentAlert(false); 
                                        setShouldShowCookieConsent(false);
                                        localStorage.setItem('cookieConsent', 'denied');
                                        //continueFromInitialPage(history, boundSetHasStartedApp, "/storesearch");
                                 }}>Decline cookies</span></div>
                                <div className="accept"><IonButton onClick={() => { 
                                        setShowConsentAlert(false); 
                                        setShouldShowCookieConsent(false);
                                        localStorage.setItem('cookieConsent', 'granted');
                                        grantCookiePermissions();
                                        //continueFromInitialPage(history, boundSetHasStartedApp, "/storesearch");
                                 }}>Accept all cookies</IonButton></div>
                            </div>
                        </div>                                
                    </div>}
                </div>
            </IonContent>
        </IonPage>
    );
};

export default SplashPage;
