import { cafeConfiguration, marketConfiguration } from '../theme/appConfiguration';

export function baseUrl() {  
    if (window.location.href.startsWith("http://localhost:")) {
        return "https://localhost:44362";

    }
    // Line below will be removed by TeamCity
    return "";
    return "/cafe";  
}

export const getConfig = () => {

    return marketConfiguration;


    //return cafeConfiguration;
    return marketConfiguration;
}

export const cacheBust = () => {

    return 1922
    

    return Date.now().toString();
}

export const continueFromInitialPage = (history: any, boundSetHasStartedApp: any, pathname: string) => {
    boundSetHasStartedApp(() => {
        history.push(pathname);
    });    
}